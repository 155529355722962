import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const customBaseQuery = async (args, api, extraOptions) => {
  try {
    const result = await fetchBaseQuery({
      baseUrl: import.meta.env.VITE_API_URL,
      prepareHeaders: (headers) => {
        headers.set('EP-App-Name', 'customer-portal');
        return headers;
      },
    })(args, api, extraOptions);
    if (result.meta && result.meta.response && !args.url.endsWith('/logout')) {
      const { status } = result.meta.response;
      if (status === 401 || status === 403) {
        if (!sessionStorage.getItem('unauthorizedEventDispatched')) {
          sessionStorage.setItem('unauthorizedEventDispatched', 'true');
          window.dispatchEvent(new CustomEvent('unauthorized'));
        }
      }
    }
    return result;
  } catch (error) {
    return { error };
  }
};

export const epportalApi = createApi({
  reducerPath: 'epportalApi',
  baseQuery: customBaseQuery,
  tagTypes: [
    'Org',
    'User',
    'LeaseContract',
    'FrameAgreement',
    'ACL',
    'LeaseObject',
    'Partner',
    'BenefitPolicy',
    'ServiceOffering',
    'TermsAndConditions',
    'PurchaseOrder',
    'Admin',
    'ServiceEvent',
    'Integration',
  ],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (user) => ({
        url: 'auth/login',
        method: 'POST',
        body: user,
        credentials: 'include',
        validateStatus: (response) => {
          return response.status === 200 || response.status === 302;
        },
      }),
    }),
    register: builder.mutation({
      query: (user) => ({
        url: 'auth/signup',
        method: 'POST',
        body: user,
      }),
    }),
    verifyEmail: builder.mutation({
      query: (code) => ({
        url: 'auth/email/verify',
        method: 'POST',
        body: { verification_code: parseInt(code, 10) },
        credentials: 'include',
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'GET',
        credentials: 'include',
        validateStatus: (response) => {
          return response.status === 200 || response.status === 302;
        },
      }),
    }),
    changePassword: builder.mutation({
      // eslint-disable-next-line camelcase
      query: ({ old_password, new_password }) => ({
        url: 'auth/password/change',
        method: 'POST',
        credentials: 'include',
        // eslint-disable-next-line camelcase
        body: { old_password, new_password },
      }),
    }),
    getOrgs: builder.query({
      query: () => ({ url: `orgs`, credentials: 'include' }),
      providesTags: ['Org'],
    }),
    getOrgById: builder.query({
      query: (orgid) => ({ url: `orgs/${orgid}`, credentials: 'include' }),
      providesTags: ['Org'],
    }),
    addOrg: builder.mutation({
      query: (initialOrg) => ({
        url: 'orgs',
        method: 'POST',
        body: initialOrg,
        credentials: 'include',
      }),
      invalidatesTags: ['Org'],
    }),
    postOrgInfo: builder.mutation({
      query: ({ orgid, info }) => ({
        url: `orgs/${orgid}/info`,
        method: 'POST',
        body: info,
        credentials: 'include',
      }),
      invalidatesTags: ['Org'],
    }),
    postOrgContact: builder.mutation({
      query: ({ orgid, contact }) => ({
        url: `orgs/${orgid}/contact`,
        method: 'POST',
        body: contact,
        credentials: 'include',
      }),
      invalidatesTags: ['Org'],
    }),
    postOrgSettings: builder.mutation({
      query: ({ orgid, settings }) => ({
        url: `orgs/${orgid}/settings`,
        method: 'POST',
        body: settings,
        credentials: 'include',
      }),
      invalidatesTags: ['Org'],
    }),
    getUsers: builder.query({
      query: () => ({ url: 'users', credentials: 'include' }),
      providesTags: ['User'],
    }),
    getUserById: builder.query({
      query: (userid) => ({ url: `users/${userid}`, credentials: 'include' }),
      providesTags: ['User'],
    }),
    getUsersByOrgId: builder.query({
      query: (orgid) => ({ url: `orgs/${orgid}/users`, credentials: 'include' }),
      providesTags: ['User'],
    }),
    addUser: builder.mutation({
      query: (user) => ({
        url: 'users',
        method: 'POST',
        body: user,
        credentials: 'include',
      }),
      invalidatesTags: ['User'],
    }),
    updateUserStatus: builder.mutation({
      query: ({ userID, status, comment }) => ({
        url: `users/${userID}/status`,
        method: 'POST',
        body: { status, comment },
        credentials: 'include',
      }),
      invalidatesTags: ['User'],
    }),
    getLeaseContracts: builder.query({
      query: () => ({ url: 'leasecontracts', credentials: 'include' }),
      providesTags: ['LeaseContract'],
    }),
    getLeaseContractsByOrgId: builder.query({
      query: (orgid) => ({ url: `orgs/${orgid}/leasecontracts`, credentials: 'include' }),
      providesTags: ['LeaseContract'],
    }),
    getLeaseContractsByUserId: builder.query({
      query: (userid) => ({ url: `users/${userid}/leasecontracts`, credentials: 'include' }),
      providesTags: ['LeaseContract'],
    }),
    getLeaseContractServiceBalance: builder.query({
      query: (lcID) => ({ url: `leasecontracts/${lcID}/service_balance`, credentials: 'include' }),
      providesTags: ['LeaseContract'],
    }),
    getLeaseContractServiceEvents: builder.query({
      query: (lcID) => ({ url: `leasecontracts/${lcID}/service_events`, credentials: 'include' }),
      providesTags: ['ServiceEvent'],
    }),
    addLeaseContract: builder.mutation({
      query: (initialLC) => ({
        url: 'leasecontracts',
        method: 'POST',
        body: initialLC,
        credentials: 'include',
      }),
      invalidatesTags: ['LeaseContract'],
    }),
    updateLeaseContract: builder.mutation({
      query: (LC) => ({
        url: `leasecontracts/${LC._id}`,
        method: 'POST',
        body: LC,
        credentials: 'include',
      }),
      invalidatesTags: ['LeaseContract'],
    }),
    updateLeaseContractState: builder.mutation({
      query: ({ lcID, state }) => ({
        url: `leasecontracts/${lcID}/state`,
        method: 'POST',
        body: { state },
        credentials: 'include',
      }),
      invalidatesTags: ['LeaseContract'],
    }),
    getFrameAgreements: builder.query({
      query: () => ({ url: `frameagreements`, credentials: 'include' }),
      providesTags: ['FrameAgreement'],
    }),
    getFrameAgreementByOrgId: builder.query({
      query: (orgID) => ({ url: `orgs/${orgID}/frameagreements`, credentials: 'include' }),
      providesTags: ['FrameAgreement'],
    }),
    getFrameAgreementById: builder.query({
      query: (frameAgreementID) => ({
        url: `frameagreements/${frameAgreementID}`,
        credentials: 'include',
      }),
      providesTags: ['FrameAgreement'],
    }),
    addFrameAgreement: builder.mutation({
      query: (initialFA) => ({
        url: 'frameagreements',
        method: 'POST',
        body: initialFA,
        credentials: 'include',
      }),
      invalidatesTags: ['FrameAgreement'],
    }),
    updateFrameAgreement: builder.mutation({
      query: (FA) => ({
        url: `frameagreements/${FA._id}`,
        method: 'POST',
        body: FA,
        credentials: 'include',
      }),
      invalidatesTags: ['FrameAgreement'],
    }),
    addUserToOrg: builder.mutation({
      query: (ACL) => ({
        url: `orgs/${ACL.targetID}/users`,
        method: 'POST',
        body: [ACL],
        credentials: 'include',
      }),
      invalidatesTags: ['User', 'Org', 'ACL'],
    }),
    deleteUserFromOrg: builder.mutation({
      query: ({ orgID, userID }) => ({
        url: `orgs/${orgID}/users/${userID}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['User', 'Org', 'ACL'],
    }),
    getUserACLs: builder.query({
      query: (userID) => ({ url: `users/${userID}/orgs`, credentials: 'include' }),
      providesTags: ['ACL'],
    }),
    getLeaseObjects: builder.query({
      query: () => ({ url: 'leaseobjects', credentials: 'include' }),
      providesTags: ['LeaseObject'],
    }),
    getLeaseObjectById: builder.query({
      query: (leaseObjectID) => ({
        url: `leaseobjects/${leaseObjectID}`,
        credentials: 'include',
      }),
      providesTags: ['LeaseObject'],
    }),
    addLeaseObject: builder.mutation({
      query: (initialLO) => ({
        url: 'leaseobjects',
        method: 'POST',
        body: initialLO,
        credentials: 'include',
      }),
      invalidatesTags: ['LeaseObject'],
    }),
    updateLeaseObject: builder.mutation({
      query: (LO) => ({
        url: `leaseobjects/${LO._id}`,
        method: 'POST',
        body: LO,
        credentials: 'include',
      }),
      invalidatesTags: ['LeaseObject'],
    }),
    getPartners: builder.query({
      query: () => ({ url: 'partners', credentials: 'include' }),
      providesTags: ['Partner'],
    }),
    addPartner: builder.mutation({
      query: (initialPP) => ({
        url: 'partners',
        method: 'POST',
        body: initialPP,
        credentials: 'include',
      }),
      invalidatesTags: ['Partner'],
    }),
    authTest: builder.query({
      query: () => ({ url: 'auth/test', credentials: 'include' }),
    }),
    addBenefitPolicy: builder.mutation({
      query: (initialBP) => ({
        url: `orgs/${initialBP.customer_id}/benefitpolicies`,
        method: 'POST',
        body: initialBP,
        credentials: 'include',
      }),
      invalidatesTags: ['BenefitPolicy'],
    }),
    getBenefitPoliciesByOrgId: builder.query({
      query: (orgID) => ({ url: `orgs/${orgID}/benefitpolicies`, credentials: 'include' }),
      providesTags: ['BenefitPolicy'],
    }),
    getBenefitPolicyById: builder.query({
      query: ({ orgID, policyID }) => ({
        url: `orgs/${orgID}/benefitpolicies/${policyID}`,
        credentials: 'include',
      }),
      providesTags: ['BenefitPolicy'],
    }),
    updateBenefitPolicy: builder.mutation({
      query: (BP) => ({
        url: `orgs/${BP.customer_id}/benefitpolicies/${BP._id}`,
        method: 'POST',
        body: BP,
        credentials: 'include',
      }),
      invalidatesTags: ['BenefitPolicy'],
    }),
    publishBenefitPolicy: builder.mutation({
      query: ({ orgID, policyID, body }) => ({
        url: `orgs/${orgID}/benefitpolicies/${policyID}/publish`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
      invalidatesTags: ['BenefitPolicy'],
    }),
    uploadBenefitPolicyFile: builder.mutation({
      query: ({ orgID, policyID, form }) => ({
        url: `orgs/${orgID}/benefitpolicies/${policyID}/draft/file`,
        method: 'POST',
        body: form,
        credentials: 'include',
      }),
      invalidatesTags: ['BenefitPolicy'],
    }),
    deleteBenefitPolicy: builder.mutation({
      query: ({ orgID, policyID }) => ({
        url: `orgs/${orgID}/benefitpolicies/${policyID}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['BenefitPolicy'],
    }),
    getServiceOfferings: builder.query({
      query: () => ({ url: 'serviceofferings', credentials: 'include' }),
      providesTags: ['ServiceOffering'],
    }),
    addServiceOffering: builder.mutation({
      query: (initialSO) => ({
        url: 'serviceofferings',
        method: 'POST',
        body: initialSO,
        credentials: 'include',
      }),
      invalidatesTags: ['ServiceOffering'],
    }),
    updateServiceOffering: builder.mutation({
      query: ({ serviceID, SO }) => ({
        url: `serviceofferings/${serviceID}`,
        method: 'POST',
        body: SO,
        credentials: 'include',
      }),
      invalidatesTags: ['ServiceOffering'],
    }),
    getTermsAndConditions: builder.query({
      query: ({ serviceID, tacID }) => ({
        url: `serviceofferings/${serviceID}/termsandconditions/${tacID}`,
        credentials: 'include',
      }),
      providesTags: ['TermsAndConditions'],
    }),
    getTermsAndConditionsByOffering: builder.query({
      query: (serviceID) => ({
        url: `serviceofferings/${serviceID}/termsandconditions`,
        credentials: 'include',
      }),
      providesTags: ['TermsAndConditions'],
    }),
    addTermsAndConditions: builder.mutation({
      query: ({ serviceID, initialTAC }) => ({
        url: `serviceofferings/${serviceID}/termsandconditions`,
        method: 'POST',
        body: initialTAC,
        credentials: 'include',
      }),
      invalidatesTags: ['TermsAndConditions'],
    }),
    updateTermsAndConditions: builder.mutation({
      query: (TAC) => ({
        url: `serviceofferings/${TAC.serviceOfferingID}/termsandconditions/${TAC._id}`,
        method: 'POST',
        body: TAC,
        credentials: 'include',
      }),
      invalidatesTags: ['TermsAndConditions'],
    }),
    addPurchaseOrder: builder.mutation({
      query: ({ user, data }) => ({
        url: `users/${user}/purchaseorders`,
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['PurchaseOrder'],
    }),
    getPurchaseOrders: builder.query({
      query: () => ({ url: 'purchaseorders', credentials: 'include' }),
      providesTags: ['PurchaseOrder'],
    }),
    getPurchaseOrdersByUserId: builder.query({
      query: (userID) => ({ url: `users/${userID}/purchaseorders`, credentials: 'include' }),
      providesTags: ['PurchaseOrder'],
    }),
    updatePurchaseOrder: builder.mutation({
      query: ({ poID, PO }) => ({
        url: `purchaseorders/${poID}`,
        method: 'POST',
        body: PO,
        credentials: 'include',
      }),
      invalidatesTags: ['PurchaseOrder'],
    }),
    getAdmins: builder.query({
      query: () => ({ url: 'system/admins', credentials: 'include' }),
      providesTags: ['Admin'],
    }),
    addAdmin: builder.mutation({
      query: (initialAdmin) => ({
        url: 'system/admins',
        method: 'POST',
        body: initialAdmin,
        credentials: 'include',
      }),
      invalidatesTags: ['Admin'],
    }),
    deleteAdmin: builder.mutation({
      query: (userID) => ({
        url: `system/admins/${userID}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['Admin'],
    }),
    getSystemInfo: builder.query({
      query: () => ({ url: 'system/info', credentials: 'include' }),
    }),
    getSystemEnv: builder.query({
      query: () => ({ url: 'system/info/env', credentials: 'include' }),
    }),
    getSystemDeps: builder.query({
      query: () => ({ url: 'system/info/dependencies', credentials: 'include' }),
    }),
    getServiceEvents: builder.query({
      query: () => ({ url: 'serviceevents', credentials: 'include' }),
      providesTags: ['ServiceEvent'],
    }),
    addServiceEvent: builder.mutation({
      query: (initialSE) => ({
        url: 'serviceevents',
        method: 'POST',
        body: initialSE,
        credentials: 'include',
      }),
      invalidatesTags: ['ServiceEvent'],
    }),
    updateServiceEvent: builder.mutation({
      query: (SE) => ({
        url: `serviceevents/${SE._id}`,
        method: 'POST',
        body: SE,
        credentials: 'include',
      }),
      invalidatesTags: ['ServiceEvent'],
    }),
    getHubspotIntegration: builder.query({
      query: () => ({
        url: 'integrations/hubspot',
        credentials: 'include',
      }),
      providesTags: ['HubspotIntegration'],
    }),
    getHubspotAccountInfo: builder.query({
      query: () => ({
        url: 'integrations/hubspot/account/info',
        credentials: 'include',
      }),
      providesTags: ['HubspotAccountInfo'],
    }),
    getHubspotAccountUsage: builder.query({
      query: () => ({
        url: 'integrations/hubspot/account/usage',
        credentials: 'include',
      }),
      providesTags: ['HubspotAccountUsage'],
    }),
    getHubSpotContactByUserId: builder.query({
      query: (userID) => ({
        url: `integrations/hubspot/contacts/userid/${userID}`,
        credentials: 'include',
      }),
    }),
    compareGrenke: builder.mutation({
      query: ({ includeMatches, form }) => ({
        url: `integrations/grenke/compare?includeMatches=${includeMatches}`,
        method: 'POST',
        body: form,
        credentials: 'include',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyEmailMutation,
  useLogoutMutation,
  useChangePasswordMutation,
  useAuthTestQuery,
  useGetOrgsQuery,
  useGetOrgByIdQuery,
  useAddOrgMutation,
  usePostOrgInfoMutation,
  usePostOrgContactMutation,
  usePostOrgSettingsMutation,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useGetUsersByOrgIdQuery,
  useAddUserMutation,
  useUpdateUserStatusMutation,
  useGetFrameAgreementsQuery,
  useGetFrameAgreementByOrgIdQuery,
  useGetFrameAgreementByIdQuery,
  useAddFrameAgreementMutation,
  useUpdateFrameAgreementMutation,
  useGetLeaseContractsQuery,
  useGetLeaseContractsByOrgIdQuery,
  useGetLeaseContractsByUserIdQuery,
  useGetLeaseContractServiceBalanceQuery,
  useGetLeaseContractServiceEventsQuery,
  useAddLeaseContractMutation,
  useUpdateLeaseContractMutation,
  useUpdateLeaseContractStateMutation,
  useAddUserToOrgMutation,
  useGetUserACLsQuery,
  useDeleteUserFromOrgMutation,
  useGetLeaseObjectsQuery,
  useGetLeaseObjectByIdQuery,
  useAddLeaseObjectMutation,
  useUpdateLeaseObjectMutation,
  useGetPartnersQuery,
  useAddPartnerMutation,
  useAddBenefitPolicyMutation,
  useUpdateBenefitPolicyMutation,
  usePublishBenefitPolicyMutation,
  useUploadBenefitPolicyFileMutation,
  useGetBenefitPoliciesByOrgIdQuery,
  useGetBenefitPolicyByIdQuery,
  useDeleteBenefitPolicyMutation,
  useGetServiceOfferingsQuery,
  useAddServiceOfferingMutation,
  useUpdateServiceOfferingMutation,
  useGetTermsAndConditionsQuery,
  useGetTermsAndConditionsByOfferingQuery,
  useAddTermsAndConditionsMutation,
  useUpdateTermsAndConditionsMutation,
  useAddPurchaseOrderMutation,
  useGetPurchaseOrdersQuery,
  useGetPurchaseOrdersByUserIdQuery,
  useUpdatePurchaseOrderMutation,
  useGetAdminsQuery,
  useAddAdminMutation,
  useDeleteAdminMutation,
  useGetSystemInfoQuery,
  useGetSystemEnvQuery,
  useGetSystemDepsQuery,
  useGetServiceEventsQuery,
  useAddServiceEventMutation,
  useUpdateServiceEventMutation,
  useGetHubspotIntegrationQuery,
  useGetHubspotAccountInfoQuery,
  useGetHubspotAccountUsageQuery,
  useGetHubSpotContactByUserIdQuery,
  useCompareGrenkeMutation,
} = epportalApi;
